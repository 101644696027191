import React from "react";

import product1 from "../assets/img/product1.png";
import product2 from "../assets/img/product2.png";
import product3 from "../assets/img/product3.png";
import product4 from "../assets/img/product4.png";
import product5 from "../assets/img/product5.png";
import product6 from "../assets/img/product6.png";
import product7 from "../assets/img/product7.png";
import product8 from "../assets/img/prouct8.png";
// import { EvohPouch } from './EvohPouch'
// import { GallaryAluminiumfoil } from './GallaryAluminiumfoil'
// import { GallaryMetalpouch } from './GallaryMetalpouch'
// import { GallaryPolyster } from './GallaryPolyster'
// import { GallaryStanduppouch } from './GallaryStanduppouch'
// import { GallaryNylon } from './GalleryNylon'
import { ProductNew } from "./ProductNew";
import { Productnew1 } from "./Productnew1";
import standupPouch1 from "../assets/img/Standuupouch/Stand-up-Pouch1.png";
import standupPouch2 from "../assets/img/Standuupouch/Stand-up-Pouch2.png";
import standupPouch3 from "../assets/img/Standuupouch/Stand-up-Pouch3.png";
import standupPouch4 from "../assets/img/Standuupouch/Stand-up-Pouch4.png";
import standupPouch5 from "../assets/img/Standuupouch/Stand-up-Pouch5.png";
import Nylon1 from "../assets/img/NylonProducts/Nylon1.png";
import Nylon2 from "../assets/img/NylonProducts/Nylon2.png";
import Nylon3 from "../assets/img/NylonProducts/Nylon3.png";
import met1 from "../assets/img/metalizePouch/met2.png";
import met2 from "../assets/img/metalizePouch/met3.png";
import met3 from "../assets/img/metalizePouch/met4.png";
import met4 from "../assets/img/metalizePouch/met1.png";
import met5 from "../assets/img/metalizePouch/met1.png";

export const Products = () => {
  return (
    <>
      <div>
        <Productnew1
          productName="High Barrier Stand Up products"
          description="Our Stand-Up Pouches are bolstered with strong plastic barrier films to keep products safe from moisture, oxygen and light and offers a long and attractive shelf life."
          img={product1}
          id="product1"
          madelheading="Stand Up pouches"
        />
      </div>
      <div>
        <ProductNew
          productName="High Barrier Metalize Polyster Pouch"
          description="Specially designed using food grade materials, Metalized Barrier Pouch are manufactured under the strict direction of our Manufacturing professionals by utilizing cutting edge techniques."
          img={product2}
          id="product2"
          img1={met1}
          img2={met2}
          img3={met3}
          img4={met4}
          img5={met5}
          targetid="#photogallery2"
        />
      </div>

      <div>
        <ProductNew
          productName="Nylon High Barrier Pouch"
          description="Nylon Films (5 & 7 layer) We are offering wide range of nylon films (5 & 7 layer). These nylon films are fabricated with high quality raw material & latest technology."
          img={product3}
          id="product3"
          madelheading="Nylon pouches"
          imgny1={Nylon1}
          imgny2={Nylon2}
          imgny3={Nylon3}
          imgny4={standupPouch4}
          imgny5={standupPouch5}
          targetid="#photogallery3"
        />
      </div>

      <div>
        <ProductNew
          productName="Aluminium Foil High Barrier Pouch"
          description="We offer high barrier aluminum foil pouches, aluminum plain foil pouches, 25 kg aluminum foil bag, triple laminated aluminum pouches, aluminum barrier foil bag.Easy to use and carry, suitable for store food and small items."
          img={product4}
          id="product4"
          targetid="#photogallery4"
        />
      </div>

      <div>
        <ProductNew
          productName="Evoh High Barrier Pouch"
          description="Plastvac is amongst India's Leading manufacturers of co-extruded Multi-layer EVOH barrier films. We offers rotogravure printing up to 8 colors. Have bag & pouch making machines for critical vacuum packing applications."
          img={product5}
          id="product5"
          targetid="#photogallery5"
        />
      </div>

      <div>
        <ProductNew
          productName="Multilayer High Barrier Polyster Pouch"
          description="We at Saurabh Plastvac ensures quality materials and efficient, affordable packaging options. Our custom-made Polyester vacuum High Barrier bags provide Product freshness. protective barrier to increase product shelf life when vacuumed packed , temperature resistant , customized aesthetics as per costumers requirements."
          img={product6}
          id="product6"
          targetid="#photogallery6"
        />
      </div>

      <div>
        <ProductNew
          productName="Thermoforming PA/PE Co Extruded FLexiible Film"
          description="Plastvac offers high-barrier rigid sheet solutions in different Thickness, intended for  Vacuum Thermoforming packaging applications for dairy products, meat, fish, coffee. tea, processed food, frozen food, compotes, baby food, dry fruits, etc. Barrier packaging guarantees that products remain in good condition, it helps  to preserve their organoleptic properties and protects against external agents such as  light, humidity, temperature and oxygen. High-barrier sheets are the optimal solution  for preserving certain foods, maximizing their shelf-life and avoiding food waste."
          img={product7}
          id="product7"
          targetid="#photogallery7"
        />
      </div>
      <div>
        <ProductNew
          productName="Lidding Films"
          description="We have one of the most diverse lidding films ranges available  on the global market today. Specially developed to protect, and  preserve, our films are mainly used in MAP (Modified  Atmosphere packing) and in Vacuum Thermoforming  machines, our market-leading lidding films include films with  superior peel ability, strength and clarity, as well as exceptional  barrier capabilities."
          img={product8}
          id="product8"
          targetid="#photogallery8"
        />
      </div>
    </>
  );
};
