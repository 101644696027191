import React from 'react'
import met1 from "../assets/img/metalizePouch/met2.png"
import met2 from "../assets/img/metalizePouch/met3.png"
import met3 from "../assets/img/metalizePouch/met4.png"
import met4 from "../assets/img/metalizePouch/met1.png"

export const GallaryMetalpouch = () => {
  return (
   <>
     <div id="cards_landscape_wrap-2" >
        <br/>
        <br/>
        <h2 style={{fontFamily:"Arial Rounded MT Bold"}}>Metalize Polyster Pouch</h2>
        <div class="container" style={{paddingTop:"0px"}}>
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <a href="">
                        <div class="card-flyer">
                            <div class="text-box">
                                <div  className='image-box'>
                                    <img src={met1} alt="image"/>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <a href="">
                        <div class="card-flyer">
                            <div class="text-box">
                                <div class="image-box">
                                    <img src={met2} alt="" />
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <a href="">
                        <div class="card-flyer">
                            <div class="text-box">
                                <div class="image-box">
                                    <img src={met3} alt="" />
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <a href="">
                        <div class="card-flyer">
                            <div class="text-box">
                                <div class="image-box">
                                    <img src={met4} alt="" />
                                </div>   
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
   </>
  )
}
