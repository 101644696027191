import React from "react";
import BgImg from "../assets/img/carousal-2.jpg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Service } from "./Service";
import { GetInTouch } from "./GetInTouch";
export const Home = () => {
  return (
    <>
      <div
        className="bgded overlay padtop"
        style={{ backgroundImage: `url(${BgImg})` }}
      >
        <div id="pageintro" className="hoc clear">
          <article className="heading-text">
            <h3 className="heading head-mob">Sustainable products</h3>
            <p className="para">
              We provide Sustainable products at very affordable prices, Let's
              do good together
            </p>
          </article>
          <ul className="inline my-5">
            <li>
              <Link
                className="btn mx-3"
                to="/products"
                style={{
                  textAlign: "left",
                  backgroundColor: "#186DB5",
                  color: "white",
                }}
              >
                Explore
              </Link>
            </li>
            <li>
              <Link
                className="btn inverse"
                to="/about"
                style={{ textAlign: "left" }}
              >
                About
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Service />
      <GetInTouch />
    </>
  );
};
