import React from 'react'

export const PhotoGallery = (props) => {
  return (
    <>
    <h1 style={{fontFamily:"Arial Rounded MT Bold"}} className="mb-5">{props.modeltitle}</h1>
    <div class="gallery" style={{paddingTop:"30px;"}}>
      <img
        src={props.img1}
        class="gallery__photo gallery__photo--p1"
        alt=""
      />
      <img
        src={props.img2}
        class="gallery__photo gallery__photo--p2"
        alt=""
      />
      <img
        src={props.img3}
        class="gallery__photo gallery__photo--p3"
        alt=""
      />
      <img
        src={props.img4}
        class="gallery__photo gallery__photo--p4"
        alt=""
      />
      <img
      src={props.img5}
      class="gallery__photo gallery__photo--p5"
      alt=""
    />
    </div>
    </>
  )
}
