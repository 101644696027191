import React from 'react'
import product1 from "../assets/img/product1.png"
import { Variant } from './Variant'
import standupPouch1 from "../assets/img/Standuupouch/Stand-up-Pouch1.png"
import standupPouch2 from "../assets/img/Standuupouch/Stand-up-Pouch2.png"
import standupPouch3 from "../assets/img/Standuupouch/Stand-up-Pouch3.png"
import standupPouch4 from "../assets/img/Standuupouch/Stand-up-Pouch4.png"
import standupPouch5 from "../assets/img/Standuupouch/Stand-up-Pouch5.png"
import { PhotoGallery } from './PhotoGallery'
export const Productnew1 = (props) => {
    return (
        <>
            {/* <!-- Modal --> */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Stand-up Pounches</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" style={{ display: "inline-block" }}>
                            <Variant />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                        </div>
                    </div>
                </div>
            </div>
            {/* model 1 */}
            <div class="modal fade" id="photogallery1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5 text-muted" id="exampleModalLabel">Saurabh PlastVac</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" style={{ backgoundColor: "F7F7F7" }} >
                            <div className='popupM'>
                                <PhotoGallery modeltitle="Stand Up Products" img1={standupPouch1} img2={standupPouch2} img3={standupPouch3} img4={standupPouch4} img5={standupPouch5} />
                            </div>
                            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>

                                </div>
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src={standupPouch1} class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src={standupPouch2} class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src={standupPouch3} class="d-block w-100" alt="..." />
                                    </div>
                                    
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                    <span style={{ backgroundColor: "black", borderRadius: "25%" }} class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden" >Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" style={{ backgroundColor: "black", borderRadius: "25%" }} aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary"><a href="https://api.whatsapp.com/send?phone=919825013570&text=Hello" style={{ color: "white" }}><i className='fa fa-whatsapp'></i></a></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cardprod mb-3" style={{ maxWidth: "850px" }} id={props.id}>
                <div class="row g-0 align-items-center">
                    <div class="col-sm-4 col-5">
                        <img src={props.img} class="img-fluid rounded-start" alt="..." />
                    </div>
                    <div class="col-sm-8 col-7">
                        <div class="card-bodyprod">
                            <hr />
                            <h5 class="card-title" style={{ marginBottom: "10px" }}><b>{props.productName}</b></h5>
                            <p class="card-textprod">{props.description}</p>
                            <a href="https://api.whatsapp.com/send?phone=919825013570&text=Hello" className='my-4' style={{ textDecoration: "none", color: "black", display: "block" }}><i className='fa fa-whatsapp'></i>   9825013570</a>
                            {/* <p class="card-text d-none d-sm-block"><small class="text-muted">Saurabh PlastVac</small></p> */}
                            {/* <div className='containerrP'>
                                <button type="button" class="btn mobilebtn  btn-sm my-2" data-bs-toggle="modal" style={{ display: "block", borderRadius: "5%", backgroundColor: "#6351CE", color: "white" }} data-bs-target="#exampleModal">
                                    Variants
                                </button>
                                <button type="button" class="btn  rounded-pill btn-sm my-2" style={{ display: "block", borderRadius: "5%", backgroundColor: "#6351CE", color: "white" }} data-bs-toggle="modal" data-bs-target="#photogallery">
                                    Tap to see more
                                </button>
                            </div> */}
                            <ul className="inline " >
                                <li><button className="btn mx-3" data-bs-toggle="modal" style={{ textAlign: "left", backgroundColor: "#186DB5", color: "white" }} data-bs-target="#exampleModal">Variants</button></li>
                                <li><button className="btn  rounded-pill btn-sm my-2" style={{ textAlign: "left", display: "block", borderRadius: "5%", backgroundColor: "#6351CE", color: "white" }} data-bs-toggle="modal" data-bs-target="#photogallery1">Tap to see more</button></li>
                            </ul>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
