import React from 'react'
import img from '../assets/img/GITimg.svg'
export const GetInTouch = () => {
  return (
    <div class="content">
        <h2 className='mt-4'>Get In Touch</h2>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div class="row justify-content-center">
            <div class="col-md-6">
              {/* <h3 class="heading mb-4"></h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p> */}
              <p><img src={img} alt="Image" class="img-fluid mt-5 mr-5"/></p>
            </div>
            <div class="col-md-6">
              
              <form class="mb-5" method="post" id="contactForm" target='_blank' action="https://formspree.io/f/mjvdyjyr" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group mt-5">
                    <input type="text" class="form-control" name="name" id="name" size="10" placeholder="First name"/>
                  </div>
                  <div class="row">
                  <div class="col-md-12 form-group my-3">
                    <input type="text" class="form-control" name="lastname" id="lastname" placeholder="Last name"/>
                  </div>
                </div>
                </div>
                <div class="row">
                  <div class="col-md-12 form-group my-3">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Email"/>
                  </div>
                </div>
               
                <div class="row">
                  <div class="col-md-12 form-group my-3">
                    <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn  rounded-0 py-2 px-4" style={{backgroundColor:"#176EB5" ,color:"white"}}/>
                  <span class="submitting"></span>
                  </div>
                </div>
              </form>
{/* 
              <div id="form-message-warning mt-4"></div> 
              <div id="form-message-success">
                Your message was sent, thank you!
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  )
}
