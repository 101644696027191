import React from "react";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

// import HashLink
export const Navbarp = () => {
  let location = useLocation();
  return (
    <div className="">
      <nav class="navbar navbar-expand-lg  navbar-dark bg-dark ">
        {/* <Link class="navbar-brand" to="/" style={{marginLeft:"10px"}}>Saurabh PlastVac</Link> */}
        <img src={logo} alt="" width={200} className="mx-5" />
        <button
          style={{ border: "none" }}
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse"
          id="navbarNavDropdown"
          style={{
            justifyContent: "flex-end",
          }}
        >
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a
                class={`nav-link ${location.pathname === "/" ? "active" : ""}`}
                href="/"
              >
                Home
              </a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="/products"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Products
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="/products">
                    High Barrier Stand Up Pouches
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="./products#product2">
                    High Barrier Metalize Polyster Pouch
                  </a>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a class="dropdown-item" href="/products#product3">
                    Nylon High Barrier Pouch
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="/products#product4">
                    Aluminium Foil High Barrier Pouch
                  </a>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a class="dropdown-item" href="/products#product5">
                    Evoh High Barrier Pouch
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="/products#product6">
                    Multilayer High Barrier Polyster Pouch
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a
                class={`nav-link ${
                  location.pathname === "/about" ? "active" : ""
                }`}
                href="/about"
              >
                About
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
