import './App.css';
import { Home } from './components/Home';
import { Navbarp } from './components/Navbarp';
import {About} from './components/About'
import { Products } from './components/Products';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  Route,
  Routes
} from "react-router-dom";
import { Footer } from './components/Footer';
import { Product1Card } from './components/Product1Card';
import { GallaryMetalpouch } from './components/GallaryMetalpouch';
import { PhotoGallery } from './components/PhotoGallery';



function App() {
  return (
    <div className="App">
    <Navbarp/>
    <Routes>
          <Route exact  path="/" element={<Home/>}/>
          <Route exact  path="/about" element={<About/>}/>
          <Route exact path="/products" element={<Products/>}/>
          <Route exact path="/gallaryMetalizepouch" element={<GallaryMetalpouch/>}/>
    </Routes>
  
    <Footer/>
    </div>
  );
}

export default App;
