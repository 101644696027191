import React from "react";
import service1 from "../assets/img/unique-design.webp";
import service2 from "../assets/img/Service2.png";
import service3 from "../assets/img/Service3.jpg";
import servive4 from "../assets/img/machine.jpg";
export const Service = () => {
  return (
    <>
      <h2 className="my-5" style={{ fontFamily: "Geneva" }}>
        {" "}
        <b>Our Services</b>{" "}
      </h2>
      <div id="cards_landscape_wrap-2">
        <div class="container" style={{ paddingBottom: "50px" }}>
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
              <a href="">
                <div class="card-flyer">
                  <div class="text-box">
                    <div class="image-box">
                      <img src={service1} alt="service" />
                    </div>
                    <div class="text-container">
                      <h6>Unique Design</h6>
                      <p>
                        Influential & Stand Out packaging design which can
                        establish instant connection between the product
                        packaging and customers are always preferred.
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
              <a href="">
                <div class="card-flyer">
                  <div class="text-box">
                    <div class="image-box">
                      <img src={service2} alt="" />
                    </div>
                    <div class="text-container">
                      <h6>Quality Material</h6>
                      <p>
                        We ensure that our technical team helps you choose the
                        right material and structure combination so that you get
                        the most optimum results for your production
                        requirement.
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
              <a href="">
                <div class="card-flyer">
                  <div class="text-box">
                    <div class="image-box">
                      <img src={service3} alt="" />
                    </div>

                    <div class="text-container">
                      <h6 style={{ fontSize: "15px" }}>
                        Customer Service & Marketing
                      </h6>
                      <p>
                        We are always eager to help and resolve query of our
                        customers so that they have hassle free experience while
                        dealing with Team Plastvac.
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
              <a href="">
                <div class="card-flyer">
                  <div class="text-box">
                    <div class="image-box">
                      <img src={servive4} alt="" />
                    </div>
                    <div class="text-container">
                      <h6>High equipped machines</h6>
                      <p>
                        We at Saurabh Plastvac Not only sell our packaging
                        material but even help our customers in understanding &
                        precuring Vacuum Packing Machine, Vacuum etc.
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <a
            href="https://drive.google.com/file/d/14rVK8TfurZNBROelw0uZB0Uaw695Twa7/view?usp=sharing"
            target="_blank"
            class="btn mt-5"
            style={{ backgroundColor: "#186DB5", color: "white" }}
          >
            Download Brochure
          </a>
        </div>
      </div>
    </>
  );
};
