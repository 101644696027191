import React from 'react'
import { Link } from 'react-router-dom'
export const Footer = () => {
    return (
        <>
            <footer class="text-center text-lg-start text-white" style={{ backgroundColor: "#1c2331" }} >
                <section
                    class="d-flex justify-content-between p-4"
                    style={{ backgroundColor: "#176EB5" }} >
                </section>
                <section>
                    <div className='container text-center text-md-start mt-5'>
                        <div className='row mt-3'>
                            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">

                                <h6 class="text-uppercase fw-bold">Saurabh PlastVac</h6>
                                <hr
                                    class="mb-4 mt-0 d-inline-block mx-auto"
                                    style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                                />
                                <p>
                                    If you're looking for high barrier vacuum pouches for preserving foods, like fresh meats, processed meats, fresh or frozen fish, cheese, and poultry, we manufacture them here in various sizes. These vacuum packaging bags extend the shelf life of food products, forming an airtight seal, and protecting against moisture and bacteria.
                                </p>
                            </div>
                            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                                <h6 class="text-uppercase fw-bold">Products</h6>
                                <hr
                                    class="mb-4 mt-0 d-inline-block mx-auto"
                                    style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                                />
                                <p>
                                    <a href="#!" class="text-white">High Barrier Stand up pouches</a>
                                </p>
                                <p>
                                    <a href="#!" class="text-white">High BArrier Metalize Polyster Pouch</a>
                                </p>
                                <p>
                                    <a href="#!" class="text-white">Nylon High Barrier Pouch</a>
                                </p>
                                <p>
                                    <a href="#!" class="text-white">Aluminium For High Barrier Pouch</a>
                                </p>
                                <p>
                                    <a href="#!" class="text-white">Evo High Barrier Pouch</a>
                                </p>
                                <p>
                                    <a href="#!" class="text-white">Multilayer High Barrier Polyster Pouch</a>
                                </p>
                            </div>
                            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

                                <h6 class="text-uppercase fw-bold">Useful links</h6>
                                <hr
                                    class="mb-4 mt-0 d-inline-block mx-auto"
                                    style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                                />
                                <p>
                                    <Link to="/" class="text-white">Home</Link>
                                </p>
                                <p>
                                    <Link to="/about"  class="text-white">About</Link>
                                </p>
                                <p>
                                    <Link to="/products" class="text-white">Products</Link>
                                </p>
                            </div>
                            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

                                <h6 class="text-uppercase fw-bold">Contact</h6>
                                <hr
                                    class="mb-4 mt-0 d-inline-block mx-auto"
                                    style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                                />
                                <p><i class="fas fa-home mr-3"></i>  63, Union Industrial Estate
                                    Nr, Gujarat Bottling
                                    Rakhial, Ahmedabad - 380 023
                                </p>
                                <p><i class="fas fa-envelope mr-3"></i>   sales@plastvac.com</p>
                                <p><i class="fa fa-whatsapp mr-3"></i>   9825013570</p>
                                <p><i class="fas fa-phone mr-3"></i>   9825013570</p>
                            </div>
                        </div>

                    </div>
                </section>
            </footer>
        </>
    )
}
