import React from "react";
import product1 from "../assets/img/product1.png";
import { Link } from "react-router-dom";
import { PhotoGallery } from "./PhotoGallery";
import Nylon1 from "../assets/img/NylonProducts/Nylon1.png";
import Nylon2 from "../assets/img/NylonProducts/Nylon2.png";
import Nylon3 from "../assets/img/NylonProducts/Nylon3.png";
import Nylon4 from "../assets/img/NylonProducts/Nylon4.png";
import Nylon5 from "../assets/img/NylonProducts/Nylon5.png";
import met1 from "../assets/img/metalizePouch/met2.png";
import met2 from "../assets/img/metalizePouch/met3.png";
import met3 from "../assets/img/metalizePouch/met4.png";
import met4 from "../assets/img/metalizePouch/met1.png";
import met5 from "../assets/img/metalizePouch/met5.png";
import alu1 from "../assets/img/Aluminiumfoil/alufoil1.png";
import alu2 from "../assets/img/Aluminiumfoil/alufoil2.png";
import alu3 from "../assets/img/Aluminiumfoil/alufoil3.png";
import alu4 from "../assets/img/Aluminiumfoil/alufoil4.png";
import alu5 from "../assets/img/Aluminiumfoil/alufoil5.png";
import evo1 from "../assets/img/EvohPouch/evoh1.png";
import evo2 from "../assets/img/EvohPouch/evoh2.png";
import evo3 from "../assets/img/EvohPouch/evoh3.png";
import evo4 from "../assets/img/EvohPouch/evoh4.png";
import evo5 from "../assets/img/EvohPouch/evoh5.png";
import poly1 from "../assets/img/PolysterPouch/polyester1.png";
import poly2 from "../assets/img/PolysterPouch/polyster2.png";
import poly3 from "../assets/img/PolysterPouch/polyster3.png";
import poly4 from "../assets/img/PolysterPouch/polyster4.png";
import poly5 from "../assets/img/PolysterPouch/polyster5.png";

export const ProductNew = (props) => {
  return (
    <>
      {/* model2 */}
      <div
        class="modal fade"
        id="photogallery2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 text-muted" id="exampleModalLabel">
                Saurabh PlastVac
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{ backgoundColor: "F7F7F7" }}>
              <div className="popupM">
                <PhotoGallery
                  modeltitle="Metalize Polyster Pouch"
                  img1={met1}
                  img2={met2}
                  img3={met3}
                  img4={met4}
                  img5={met5}
                />
              </div>
              <div
                id="carouselExampleIndicators1"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators1"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators1"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators1"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src={met1} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={met2} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={met3} class="d-block w-100" alt="..." />
                  </div>
                  {/* <div class="carousel-item">
                    <img src={met4} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={met5} class="d-block w-100" alt="..." />
                  </div> */}
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators1"
                  data-bs-slide="prev"
                >
                  <span
                    style={{ backgroundColor: "black", borderRadius: "25%" }}
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators1"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    style={{ backgroundColor: "black", borderRadius: "25%" }}
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary">
                <a
                  href="https://api.whatsapp.com/send?phone=919825013570&text=Hello"
                  style={{ color: "white" }}
                >
                  <i className="fa fa-whatsapp"></i>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* model 3 */}
      <div
        class="modal fade"
        id="photogallery3"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 text-muted" id="exampleModalLabel">
                Saurabh PlastVac
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{ backgoundColor: "F7F7F7" }}>
              <div className="popupM">
                <PhotoGallery
                  modeltitle="Nylon High Barrier Pouch"
                  img1={Nylon1}
                  img2={Nylon2}
                  img3={Nylon3}
                  img4={Nylon4}
                  img5={Nylon5}
                />
              </div>
            </div>
            <div
              id="carouselExampleIndicators2"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators2"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators2"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators2"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src={Nylon1} class="d-block w-100" alt="..." />
                </div>
                <div class="carousel-item">
                  <img src={Nylon2} class="d-block w-100" alt="..." />
                </div>
                <div class="carousel-item">
                  <img src={Nylon3} class="d-block w-100" alt="..." />
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators2"
                data-bs-slide="prev"
              >
                <span
                  style={{ backgroundColor: "black", borderRadius: "25%" }}
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators2"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  style={{ backgroundColor: "black", borderRadius: "25%" }}
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary">
                <a
                  href="https://api.whatsapp.com/send?phone=919825013570&text=Hello"
                  style={{ color: "white" }}
                >
                  <i className="fa fa-whatsapp"></i>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* model 4 */}
      <div
        class="modal fade"
        id="photogallery4"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 text-muted" id="exampleModalLabel">
                Saurabh PlastVac
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{ backgoundColor: "F7F7F7" }}>
              <div className="popupM">
                <PhotoGallery
                  modeltitle="Aluminium Foil High Barrier Pouch"
                  img1={alu1}
                  img2={alu2}
                  img3={alu3}
                  img4={alu4}
                  img5={alu5}
                />
              </div>
              <div
                id="carouselExampleIndicators3"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators3"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators3"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators3"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src={alu1} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={alu2} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={alu3} class="d-block w-100" alt="..." />
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators3"
                  data-bs-slide="prev"
                >
                  <span
                    style={{ backgroundColor: "black", borderRadius: "25%" }}
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators3"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    style={{ backgroundColor: "black", borderRadius: "25%" }}
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary">
                <a
                  href="https://api.whatsapp.com/send?phone=919825013570&text=Hello"
                  style={{ color: "white" }}
                >
                  <i className="fa fa-whatsapp"></i>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* model 5 */}
      <div
        class="modal fade"
        id="photogallery5"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 text-muted" id="exampleModalLabel">
                Saurabh PlastVac
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{ backgoundColor: "F7F7F7" }}>
              <div className="popupM">
                <PhotoGallery
                  modeltitle="Evoh High Barrier Pouch"
                  img1={evo1}
                  img2={evo2}
                  img3={evo3}
                  img4={evo4}
                  img5={evo5}
                />
              </div>
              <div
                id="carouselExampleIndicators5"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators5"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators5"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators5"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src={evo1} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={evo2} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={evo3} class="d-block w-100" alt="..." />
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators5"
                  data-bs-slide="prev"
                >
                  <span
                    style={{ backgroundColor: "black", borderRadius: "25%" }}
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators5"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    style={{ backgroundColor: "black", borderRadius: "25%" }}
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary">
                <a
                  href="https://api.whatsapp.com/send?phone=919825013570&text=Hello"
                  style={{ color: "white" }}
                >
                  <i className="fa fa-whatsapp"></i>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* model 6 */}
      <div
        class="modal fade"
        id="photogallery6"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 text-muted" id="exampleModalLabel">
                Saurabh PlastVac
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{ backgoundColor: "F7F7F7" }}>
              <div className="popupM">
                <PhotoGallery
                  modeltitle="Multilayer High Barrier Polyster Pouch"
                  img1={poly1}
                  img2={poly2}
                  img3={poly3}
                  img4={poly4}
                  img5={poly5}
                />
              </div>
              <div
                id="carouselExampleIndicators6"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators6"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators6"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators6"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src={poly4} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={poly2} class="d-block w-100" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src={poly5} class="d-block w-100" alt="..." />
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators6"
                  data-bs-slide="prev"
                >
                  <span
                    style={{ backgroundColor: "black", borderRadius: "25%" }}
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators6"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    style={{ backgroundColor: "black", borderRadius: "25%" }}
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary">
                <a
                  href="https://api.whatsapp.com/send?phone=919825013570&text=Hello"
                  style={{ color: "white" }}
                >
                  <i className="fa fa-whatsapp"></i>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="cardprod mb-3" style={{ maxWidth: "850px" }} id={props.id}>
        <div class="row g-0 align-items-center">
          <div class="col-sm-4 col-5">
            <img src={props.img} class="img-fluid rounded-start" alt="..." />
          </div>
          <div class="col-sm-8 col-7">
            <div class="card-bodyprod">
              <hr />
              <h5 class="card-title" style={{ marginBottom: "10px" }}>
                <b>{props.productName}</b>
              </h5>
              <p class="card-textprod">{props.description}</p>
              <a
                href="https://api.whatsapp.com/send?phone=919825013570&text=Hello"
                className="my-4 "
                style={{
                  textDecoration: "none",
                  color: "green",
                }}
              >
                <i className="fa fa-whatsapp"></i> 9825013570
              </a>
              <button
                type="button"
                class="btn taptosee rounded-pill btn-sm my-2"
                style={{
                  display: "block",
                  borderRadius: "5%",
                  backgroundColor: "#6351CE",
                  color: "white",
                }}
                data-bs-toggle="modal"
                data-bs-target={props.targetid}
              >
                Tap to see more
              </button>
              {/* <p class="card-text d-none d-sm-block"><small class="text-muted">Saurabh PlastVac</small></p> */}
              <hr />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
