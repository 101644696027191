import React from 'react'
import "../About.css"
import image from "../assets/img/01.png"
import image1 from "../assets/img/aboutImage.png"

export const About = () => {
  return (
    <>
    <div className='container'>
      <img className='visibleonmobile' src={image1}/>
    </div>
      <div class="about-section">
        <div class="inner-container">
          <h1>About Us</h1>
          <p class="text">
            Saurabh PlastVac is in to manufacturing of High Barrier Multilayer Vacuum pouches & Roll Stock since Past Many Years with an aim of manufacturing high quality Superior Quality Multilayer Vacuum Pouches. The Company is having a strong hold in Vacuum Pouches and its various Structure. Saurabh PlastVac started with manufacturing capacity of 1 ton per month at that time. The company has since climbed up the growth ladder and today prides in its leadership position in the manufacturing of high-quality High Barrier Vacuum Pouches. Today the company’s manufacturing capacity is up to 100 tons per month.
          </p>
            <h6><i class="fa-solid fa-location-dot" style={{textAlign:"center", display:"block"}}></i></h6>
              <p>
                63, Union Industrial Estate
                Nr, Gujarat Bottling
                Rakhial, Ahmedabad - 380 023
              </p>
              </div>
      </div>
    </>
  )
}
