import React from 'react'

export const Variant = () => {
    return (
        <>
         <div className='' >
         <div class="pcard">
                <div class="containerp">
                    <h6 className='my-3'><b>Food/candy/snack packaging</b></h6>
                    <h6 className='my-3'><b>Personal care items</b></h6>
                    <h6 className='my-3'><b>Pet treat packaging</b></h6>
                    <h6 className='my-3'><b>Spices</b></h6>
                    <h6 className='my-3'><b>Frozen food packaging</b></h6>
                    <h6 className='my-3'><b>Seafood packaging</b></h6>
                    <h6 className='my-3'><b>Dishwasher detergent packaging</b></h6>
                    <h6 className='my-3'><b>Supplement packaging</b></h6>
                    <h6 className='my-3'><b>Toy packaging</b></h6>
                    <h6 className='my-3'><b>Air fresheners</b></h6>
                    <h6 className='my-3'><b>Hardware packaging</b></h6>
                    <h6 className='my-3'><b>Dairy Products.</b></h6>
                    <h6 className='my-3'><b>Liquid & Semi Liquid Products.</b></h6>
                    <hr />
                    <i className='fa fa-whatsapp'></i><span><a style={{ color: "#212529", textDecoration: "none" }} href='https://api.whatsapp.com/send?phone=919825013570&text=Hello'>    9825013570</a></span>
                </div>
            </div>
         </div>
        </>
    )
}
